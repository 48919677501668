import { render, staticRenderFns } from "./GeneratedReports.vue?vue&type=template&id=ca88dcea&scoped=true&"
import script from "./GeneratedReports.vue?vue&type=script&lang=js&"
export * from "./GeneratedReports.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca88dcea",
  null
  
)

export default component.exports