<template>
  <div class="mx-1">
    <generated-reports :reports-available="reportsAvailable" />
  </div>
</template>

<script>
import GeneratedReports from "@/components/GeneratedReports";

export default {
  data() {
    return {
      reportsAvailable: ["TITULTIGI"],
    };
  },
  components: { GeneratedReports },
};
</script>
