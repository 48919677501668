<template>
  <div class="mx-1">
    <generated-reports :reports-available="reportsAvailable" />
  </div>
</template>

<script>
import GeneratedReports from "@/components/GeneratedReports";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  mixins: [StorageGetterMixin],
  data() {
    return {
      reportsAvailable: ["INCAPAGA", "COMINCA", "INCACOMP2"],
    };
  },
  components: { GeneratedReports },
  methods: {
    getReports() {
      this.reports = this.$store.state.auth.reports.filter((e) =>
        this.reportsAvailable.includes(e.code)
      );
      this.reportOptions = this.reports.map((e) => {
        return {
          value: e.code,
          text: e.title,
          disabled: false,
        };
      });
    },
  },
  created() {
    /* */
    console.log("created index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {
            console.info(`background data "${path}reports" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.getReports();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.getReports();
      this.isLoading = false;
    }
    /* */
  },
};
</script>
