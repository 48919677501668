<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Reports from "@/components/wallet/generated-reports/index";
import CreateReport from "@/components/wallet/generated-reports/create";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.PFOLIO.REPG",
      tabs: [
        { name: "Reports", text: "Visualizza Report" },
        { name: "CreateReport", text: "Crea Report" },
      ],
      tabsGroup: "GeneratedReports",
      bcs: [
        {
          text: "Portafoglio",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Portafoglio",
          path: "",
          level: 1,
        },
        {
          text: "Report Generati",
          path: "",
          level: 2,
        },
        {
          text: "Visualizza Report",
          path: "module.PFOLIO.REPG",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3,
      selected: "Reports",
      tabIndex: 0,
    };
  },
  components: {
    Reports,
    CreateReport,
  },
};
</script>
